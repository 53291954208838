import { router } from 'routers'
import 'leaflet/dist/leaflet.js'
import 'leaflet/dist/leaflet.css'
import { theme } from 'config/material'
import ReactDOM from 'react-dom/client'
import { CssBaseline } from '@mui/material'
import { Spinner } from 'components/spinner'
import { StrictMode, Suspense } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import reportWebVitals from './reportWebVitals'
import { queryClient } from 'config/react-query'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<StrictMode>
		<Suspense fallback={<Spinner />}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<RouterProvider router={router} />
					<CssBaseline />
					<ToastContainer />
				</ThemeProvider>
			</QueryClientProvider>
		</Suspense>
	</StrictMode>,
)

reportWebVitals()
