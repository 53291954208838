import { createTheme } from '@mui/material/styles'

export const breakpoints = {
	mobile: 0,
	tablet: 640,
	laptop: 1024,
	desktop: 1400,
}

export const defaultTheme = createTheme({
	breakpoints: {
		values: breakpoints,
	},
})
