import { Box } from '@mui/material'
import { toast } from 'react-toastify'

export const notifySuccess = (text: string) => {
	toast.success(text, {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		progressClassName: 'toastProgress',
	})
}

export const notifyError = (text: string) => {
	toast.error(text, {
		position: 'top-right',
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		progressClassName: 'toastProgress',
	})
}

export const notifyInfo = (text: string) => {
	toast.info(text, {
		position: 'top-center',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		progressClassName: 'toastProgress',
	})
}

export const notifyWarning = (text: string, toastId: string) => {
	toast.warning(text, {
		draggable: true,
		autoClose: false,
		theme: 'colored',
		position: 'top-right',
		hideProgressBar: true,
		toastId,
	})
}

interface INotMessage {
	id: string
	title: string
	message: string
}

export const notifyMessage = (data: INotMessage) => {
	toast.info(
		(props: any) => {
			return (
				<Box display='flex' flexDirection='column'>
					<div>{props.data.title}</div>
					<div>{props.data.message}</div>
				</Box>
			)
		},
		{
			data,
			toastId: data.id,
			position: 'top-right',
			autoClose: false,
			hideProgressBar: true,
			closeOnClick: true,
			draggable: true,
			theme: 'colored',
			style: {
				color: 'white',
				background: 'var(--main-color)',
			},
		},
	)
}
