import { defaultTheme } from './breakpoints';
import { radioClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { checkboxClasses } from '@mui/material/Checkbox';
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    green: true;
    red: true;
    logout: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    mainTitle: React.CSSProperties;
    highLight: React.CSSProperties;
    modalTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mainTitle?: React.CSSProperties;
    highLight: React.CSSProperties;
    modalTitle: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    mainTitle: true;
    highLight: true;
    modalTitle: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#7f7eff',
    },
  },
  typography: {
    mainTitle: {
      fontSize: 26,
      fontWeight: 600,
      color: 'var(--black)',
      [defaultTheme.breakpoints.up('desktop')]: {
        fontSize: 30,
      },
    },
    highLight: {
      fontSize: 24,
      fontWeight: 600,
      color: 'var(--main-color)',
      [defaultTheme.breakpoints.up('desktop')]: {
        fontSize: 30,
      },
    },
    modalTitle: {
      fontSize: 24,
      fontWeight: 600,
      color: 'var(--black)',
      [defaultTheme.breakpoints.up('desktop')]: {
        fontSize: 30,
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          fontWeight: 600,
          fontSize: '16px',
          borderRadius: 10,
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          minWidth: 'fit-content',
          textTransform: 'capitalize',
          '&.Mui-disabled': {
            opacity: '1 !important',
            cursor: 'not-allowed !important',
            pointerEvents: 'auto !important',
            color: 'var(--light-purple) !important',
            background: 'var(--purple40) !important',
          },
          '&.Mui-disabled:hover': {
            boxShadow: 'none !important',
          },
          [defaultTheme.breakpoints.up('desktop')]: {
            height: 50,
            fontSize: '18px',
            borderRadius: 12,
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: 'white',
            background: 'var(--main-color)',
            boxShadow: '0px 8px 35px var(--purple10)',
            '&:hover': {
              opacity: 0.8,
              color: 'white',
              background: 'var(--main-color)',
              boxShadow: '0px 8px 35px var(--purple10)',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: 'var(--gray50)',
            background: 'var(--gray40)',
          },
        },
        {
          props: { variant: 'green' },
          style: {
            height: 30,
            fontWeight: 400,
            borderRadius: 8,
            fontSize: '15px',
            color: 'var(--green)',
            backgroundColor: 'var(--green20)',
            '&:hover': {
              color: 'var(--green)',
              backgroundColor: 'var(--green20)',
            },
            [defaultTheme.breakpoints.up('desktop')]: {
              height: 40,
              fontSize: '16px',
              borderRadius: 10,
            },
          },
        },
        {
          props: { variant: 'red' },
          style: {
            height: 30,
            fontSize: '15px',
            fontWeight: 400,
            borderRadius: 8,
            color: 'var(--red20)',
            backgroundColor: 'var(--red10)',
            '&:hover': {
              color: 'var(--red20)',
              backgroundColor: 'var(--red10)',
            },
            [defaultTheme.breakpoints.up('desktop')]: {
              height: 40,
              fontSize: '16px',
              borderRadius: 10,
            },
          },
        },
        {
          props: { variant: 'logout' },
          style: {
            height: 35,
            fontSize: 13,
            paddingLeft: 0,
            fontWeight: 400,
            borderRadius: 6,
            color: 'var(--gray10)',
            display: 'flex',
            justifyContent: 'flex-start',
            transition: 'all 0.3s ease',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'var(--purple50)',
            },
            [defaultTheme.breakpoints.up('desktop')]: {
              height: 45,
              fontSize: 14,
              borderRadius: 8,
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            root: {
              backgroundColor: 'white !important',
            },
            input: {
              '&::placeholder': {
                opacity: 1,
                fontSize: 16,
                fontWeight: 300,
                color: 'var(--gray210)',
              },
            },
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            input: {
              '&::placeholder': {
                opacity: 1,
                fontSize: 14,
                fontWeight: 400,
                color: 'var(--gray50)',
              },
              borderRadius: 10,
              background: 'var(--gray40) !important',
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '12px !important',
          [defaultTheme.breakpoints.up('desktop')]: {
            borderRadius: '10px !important',
          },
        },
        input: {
          padding: '8.5px 16px!important',
          [defaultTheme.breakpoints.up('desktop')]: {
            padding: '13.5px 26px!important',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'var(--gray20)',
          [`&.${checkboxClasses.checked}`]: {
            color: 'var(--main-color)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'white',
          '& fieldset': {
            borderColor: 'var(--black30)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--black30)',
          },
          '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--main-color)',
            boxShadow: '0px 0px 0px 3px var(--purple10)',
          },
          '&.Mui-error  .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--red)',
            boxShadow: '0px 0px 0px 3px var(--red10)',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'var(--gray60)',
          [`&.${radioClasses.checked}`]: {
            color: 'var(--main-color)',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '6px !important',
          '& .MuiMenuItem-root': {
            borderRadius: '6px !important',
            fontWeight: 400,
            fontSize: '16px',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px !important',
          boxShadow: '0px 2px 30px var(--black60)',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          maxHeight: 'fit-content !important',
          minHeight: 'fit-content !important',
          '& .Mui-selected': {
            color: 'var(--black) !important',
          },
          '& .MuiButtonBase-root:not(.Mui-selected)': {
            color: 'var(--gray70)',
          },
          '& .MuiButtonBase-root': {
            textTransform: 'capitalize',
            padding: '0 20px',
            alignItems: 'flex-start',
            fontWeight: 300,
            fontSize: 16,
            borderRight: '1px solid var(--gray10)',
            maxHeight: 'fit-content !important',
            minHeight: 'fit-content !important',
            maxWidth: 'fit-content !important',
            minWidth: 'fit-content !important',
          },
          '& .MuiButtonBase-root:nth-of-type(1)': {
            padding: '0 20px 0 0',
          },
          '& .MuiButtonBase-root:last-child': {
            borderRight: 'none',
          },
          '& .MuiTabs-scrollButtons': {
            padding: '0 !important',
            borderRight: 'none !important',
          },
        },
      },
    },
  },
});
