import { clearStorage } from 'utils/clearStorage'
import { notifyError } from 'components/notification'
import { QueryCache, QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 0,
		},
	},
	queryCache: new QueryCache({
		onError: (error: any) => {
			if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
				notifyError(error.response.statusText)
				clearStorage()
				window.location.href = '/'
			} else {
				if (error?.response?.data?.detail) {
					notifyError(error?.response?.data?.detail)
				} else {
					notifyError(error.message)
				}
			}
		},
	}),
})
