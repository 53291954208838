import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

// auth and layout
const AuthLayout = lazy(() => import('layout/auth'))
const Login = lazy(() => import('pages/auth/login'))
const Reset = lazy(() => import('pages/auth/reset'))
const Forget = lazy(() => import('pages/auth/forget'))
const SignUp = lazy(() => import('pages/auth/sign-up'))
// error

// main layout
const DashboardLayout = lazy(() => import('layout/dashboard'))

// manager
const ManagerHome = lazy(() => import('pages/manager/home'))
const ManagerHomeDetail = lazy(() => import('pages/manager/home-detail'))
const ManagerTrailers = lazy(() => import('pages/manager/trailers'))
const DriverHistory = lazy(() => import('pages/manager/driver-history'))
const TrailerHistory = lazy(() => import('pages/manager/trailer-history'))
const Drivers = lazy(() => import('pages/manager/drivers'))
const Message = lazy(() => import('pages/manager/message'))
const SelectedChat = lazy(() => import('pages/manager/selected-chat'))
const Access = lazy(() => import('pages/manager/access'))

// lease
const LeaseHome = lazy(() => import('pages/lease/home'))
const LeaseHomeDetail = lazy(() => import('pages/lease/home-detail'))
const Companies = lazy(() => import('pages/lease/companies'))
const LeaseTrailers = lazy(() => import('pages/lease/trailers'))
const Managers = lazy(() => import('pages/lease/managers'))

// not found
const NotFound = lazy(() => import('pages/notfound'))

export const router = createBrowserRouter([
	{
		path: '',
		element: <AuthLayout />,
		children: [
			{
				index: true,
				element: <Login />,
			},
			{ path: 'reset/:rest_token', element: <Reset /> },
			{ path: 'forget', element: <Forget /> },
			{ path: 'invitation/:invitation_token', element: <SignUp /> },
		],
	},
	{
		path: 'manager',
		element: <DashboardLayout />,
		children: [
			{
				path: 'home',
				children: [
					{
						index: true,
						element: <ManagerHome />,
					},
					{
						path: ':managerDeviceId',
						element: <ManagerHomeDetail />,
					},
				],
			},
			{
				path: 'trailers',
				children: [
					{ index: true, element: <ManagerTrailers /> },
					{ path: ':trailerId', element: <TrailerHistory /> },
				],
			},
			{
				path: 'drivers',
				children: [
					{ index: true, element: <Drivers /> },
					{ path: ':driverId', element: <DriverHistory /> },
				],
			},
			{
				path: 'message',
				element: <Message />,
				children: [
					{
						path: ':chatId',
						element: <SelectedChat />,
					},
				],
			},
			{ path: 'access', element: <Access /> },
		],
	},
	{
		path: 'lease',
		element: <DashboardLayout />,
		children: [
			{
				path: 'home',
				children: [
					{
						index: true,
						element: <LeaseHome />,
					},
					{
						path: ':leaseCompanyId',
						element: <LeaseHomeDetail />,
					},
				],
			},
			{ path: 'trailers', element: <LeaseTrailers /> },
			{ path: 'companies', element: <Companies /> },
			{ path: 'managers', element: <Managers /> },
		],
	},
	{
		path: '*',
		element: <NotFound />,
	},
])
