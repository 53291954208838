import styled from '@emotion/styled'
import { PropTypes } from './spinner'

export const Container = styled.div<PropTypes>`
	${({ center }) =>
		center &&
		'width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;'}
	.MuiCircularProgress-root {
		color: var(--main-color);
	}
`
